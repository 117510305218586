@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');
@import 'src/styles/variables';
@import 'src/styles/mixins';

* {
  box-sizing: border-box;
}

html,
body {
  overscroll-behavior: none;
}

body {
  --black: #000;
  --white: #fff;
  --gray: gray;
  --font-family: 'Archivo', sans-serif;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
  border: none;
  color: $white;
}

::-webkit-scrollbar {
  display: none;
}

html,
body,
#root {
  font-weight: 500;
  transition: all 0.3s;
  color: $white;
  background-color: #0e0e0e;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type='date'] {
  width: 100%;
  min-width: 100%;
  min-height: 46px;
  height: 46px;
  box-sizing: border-box;
  text-align: left;
  display: block;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  text-transform: uppercase;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

.custom-slide {
  box-sizing: border-box;
}

.swiper-pagination {
  display: flex;
  gap: 5px;
  justify-content: center;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.show {
  animation: show 0.3s ease-in-out;
}

.hide {
  animation: hide 0.3s ease-in-out;
}

.modal-opened {
  overflow: hidden;
}

.telegram-webview .input-footer {
  display: none !important;
}

.telegram-webview .main-button {
  display: none !important;
}

.telegram-webview .back-button {
  display: none !important;
}
